@import '~bulma/sass/utilities/all';

.is-success {
  .bulma__control {
    border-color: $green;
    &:hover {
      border-color: darken($green, 10%);
    }
  }
}
.is-danger {
  .bulma__control {
    border-color: $red;
    &:hover {
      border-color: darken($red, 10%);
    }
  }
}