.container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  font-size: 30px;

  position: fixed;
  right: 25px;
  top: 25px;
  z-index: -999;

  @media (max-width: 1024px) {
    width: 40px;
    height: 40px;
    font-size: 30px;
  }

  background: #fff;
  border: 2px solid #4a4a4a;
  border-radius: 5px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);

  transition: opacity 100ms;
  opacity: 0;

  cursor: pointer;
}

.container.show {
  transform: rotate3d(1, 0, 0, 360deg);
  opacity: 1;
  z-index: 999;
  transition: opacity 250ms, transform 500ms ease-out;
}