@import "~bulma/sass/utilities/all";

.navbar {
  @include from($desktop) {
    &.is-white {
      .active-page {
        border-bottom: 1px solid $grey-light;
      }
    }
    &:not(.is-white) {
      .active-page::after {
        border: 8px solid transparent;
        border-bottom-color: white;
        bottom: -1px;
        content: "";
        height: 0;
        left: calc(50% - 8px);
        position: absolute;
        width: 0;
      }
    }
  }

  @include until($desktop) {
    .active-page:not(.has-dropdown) {
      background-color: #8875B0;
      color: #ffffff;
    }
    .navbar-menu {
      padding: 0;
    }
  }
}

.navbar-menu {
  @include from($desktop) {
    margin-right: 3.25rem;
  }
}