@keyframes grab-attention {
    0% {transform: scale(1);}
    50% {transform: scale(1.05);}
    100% {transform: scale(1);}
}

.confirming {
    font-weight: bold;
    animation: grab-attention;
    animation-duration: .3s;
    animation-timing-function: ease;
}