.notFound {
    width: 100%;
    height: 500px;
    background-image: url("../../images/404.jpg");
    background-image: image-set("../../images/404.jpg" 1x, "../../images/404@2x.jpg" 2x);
    background-repeat: no-repeat;
    background-position-x: 50%;
    z-index: -999;

    @media (max-width: 500px) {
        height: calc(110vw);
        background-size: 160%;
    }
}