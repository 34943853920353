$primary: #8875B0;
$family-sans-serif: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
@import "~bulma";

#root {
  position: absolute;
  min-height: 100vh;
  min-width: 100%;
  max-width: 100%;
}

.title {
  font-size: 3rem;
  margin-bottom: 1.75rem !important;
}

.font-branded {
  font-family: 'Permanent Marker', sans-serif;
}

.font-avalon {
  font-family: 'Berkshire Swash', cursive;
}

.font-spyfall {
  font-family: 'Cutive Mono', Courier New, Courier, monospace;
  font-weight: bold;
}

.font-clueless {
  font-family: 'Amatic SC', sans-serif;
  font-size: 130%;
}

.font-heist {
  font-family: 'Bebas Neue', Tahoma, sans-serif;
  font-size: 120%;
  line-height: 85%;
}

//.font-deadlast { // This font is available on Google Fonts
//  font-family: 'Lacquer', "Arial Black", sans-serif;
//}

.section {
  padding: 1.5rem !important;
}

.label {
  text-transform: uppercase;
  font-size: 0.8em;
  margin-bottom: 0 !important;
}

.field .field {
  margin-bottom: 0;
}

a:hover {
  color: darken($link, 10%);
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.link {
  @extend .has-text-link;
  @extend .clickable;
}

.table {
  margin-bottom: 0 !important;
}

.initial-list {
  ul, ol {
    margin-left: 1.5rem;
  }
  li {
    list-style-type: initial;
  }
}